<template>
  <div class="register-page register-sign-up-page">
    <breadCrumbs :current="1" :total="6"></breadCrumbs>
    <div class="container-wide">
      <img src="/static/img/logo-soco.svg" class="logo-soco" alt="register-cover-image" />
      <h2 class="register-tile">MEMBUAT AKUN SOCO</h2>
      <p class="main-desc text-center">
        <strong>Mendaftarkan diri Anda ke SOCO.</strong>
      </p>
      <p class="main-desc text-center mb-48">
        Jika Anda sudah memiliki akun SOCO silakan login lewat web soco.id atau download aplikasi mobile yang tersedia
        di iOS dan android.
      </p>

      <form
        id="form-register"
        method="post"
        name="form-register"
        data-vv-scope="form-register"
        @submit.prevent="submitSignUp('form-register')"
      >
        <div class="form-inline-wrap">
          <div class="input-box" :class="{ 'has-error': errors.has('form-register.first_name') }">
            <!-- <label for="first_name" v-show="registerData.first_name.length">Nama Depan</label> -->
            <div class="fieldarea">
              <input
                id="first_name"
                v-model="registerData.first_name"
                v-validate="'required|alpha_dash|min:1|max:32'"
                type="text"
                class="text default"
                placeholder="Nama Depan"
                name="first_name"
                autocomplete="off"
              />
              <p v-show="errors.has('form-register.first_name')" class="alert">
                {{ errors.first('form-register.first_name') }}
              </p>
            </div>
          </div>
          <div class="input-box" :class="{ 'has-error': errors.has('form-register.last_name') }">
            <!-- <label for="last_name" v-show="registerData.last_name.length">Nama Belakang</label> -->
            <div class="fieldarea">
              <input
                id="last_name"
                v-model="registerData.last_name"
                v-validate="'required|alpha_dash|min:1|max:32'"
                type="text"
                class="text default"
                placeholder="Nama Belakang"
                name="last_name"
                autocomplete="off"
              />
              <p v-show="errors.has('form-register.last_name')" class="alert">
                {{ errors.first('form-register.last_name') }}
              </p>
            </div>
          </div>
        </div>

        <div class="input-box" :class="{ 'has-error': errors.has('form-register.reguser_name') }">
          <!-- <label for="reguser_name" v-show="registerData.user_name">user_name</label> -->
          <div class="fieldarea">
            <input
              id="reguser_name"
              v-model="registerData.user_name"
              v-validate="'required|alpha_dash|min:4|max:16'"
              type="text"
              class="text default"
              placeholder="Username"
              name="reguser_name"
              autocomplete="off"
            />
            <p v-show="errors.has('form-register.reguser_name')" class="alert">
              {{ errors.first('form-register.reguser_name') }}
            </p>
          </div>
        </div>

        <div class="input-box" :class="{ 'has-error': errors.has('form-register.email') }">
          <!-- <label for="email" v-show="registerData.email">Email</label> -->
          <div class="fieldarea">
            <input
              id="email"
              v-model="registerData.email"
              v-validate="'required|email'"
              type="text"
              class="text default"
              placeholder="Email"
              name="email"
              autocomplete="off"
            />
            <p v-show="errors.has('form-register.email')" class="alert">{{ errors.first('form-register.email') }}</p>
          </div>
        </div>

        <div class="input-box" :class="{ 'has-error': errors.has('form-register.regpassword') }">
          <!-- <label for="regpassword" v-show="registerData.password">Password</label> -->
          <div class="fieldarea password">
            <input
              id="password"
              ref="password"
              v-model="registerData.password"
              v-validate="'required|min:6|max:60'"
              class="text default"
              placeholder="Password"
              name="password"
              autocomplete="off"
              :type="revealPassword"
            />
            <span class="reveal" :class="{ show: revealPassword == 'text' }" @click="togglePassword"></span>
            <p v-show="errors.has('form-register.password')" class="alert">
              {{ errors.first('form-register.password') }}
            </p>
          </div>
        </div>

        <div class="input-box" :class="{ 'has-error': errors.has('form-register.repassword') }">
          <!-- <label for="repassword" v-show="registerData.repassword">Konfirmasi Password</label> -->
          <div class="fieldarea password">
            <input
              id="repassword"
              v-model="registerData.repassword"
              v-validate="'required|confirmed:password'"
              class="text default"
              placeholder="Konfirmasi Password"
              name="repassword"
              autocomplete="off"
              :type="revealRePassword"
            />
            <span class="reveal" :class="{ show: revealRePassword == 'text' }" @click="toggleRePassword"></span>
            <p v-show="errors.has('form-register.repassword')" class="alert">
              {{ errors.first('form-register.repassword') }}
            </p>
          </div>
        </div>

        <div class="fixed-bottom">
          <router-link v-if="showLoader == false" to="/register" class="btn half-full" type="submit">
            <i class="arrow_carrot-left_alt"></i>
            BATAL
          </router-link>
          <button
            v-if="showLoader == false"
            :disabled="errors.any() || !isDirty"
            class="btn half-full submit"
            type="submit"
          >
            DAFTAR KE SOCO
            <i class="arrow_carrot-right_alt"></i>
          </button>
          <!-- <loaderspin v-else/> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import breadCrumbs from './bread-crumbs';

export default {
  name: 'register',
  components: {
    breadCrumbs,
  },
  data() {
    return {
      revealPassword: 'password',
      revealRePassword: 'password',
      showLoader: false,
      registerData: {
        user_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repassword: '',
      },
    };
  },
  computed: {
    isDirty() {
      return (
        this.registerData.user_name &&
        this.registerData.first_name &&
        this.registerData.last_name &&
        this.registerData.email &&
        this.registerData.password &&
        this.registerData.repassword
      );
    },
  },
  mounted() {
    // for testing register with unique auto generated uname and pass
    // this.registerData.user_name =
    //   this.registerData.user_name + Math.floor(Math.random() * 999) + "s";
    // this.registerData.email =
    //   this.registerData.email + Math.random() + `@gmail.cow`;
  },
  methods: {
    toggleRePassword() {
      if (this.revealRePassword === 'password') {
        this.revealRePassword = 'text';
      } else {
        this.revealRePassword = 'password';
      }
    },
    togglePassword() {
      if (this.revealPassword === 'password') {
        this.revealPassword = 'text';
      } else {
        this.revealPassword = 'password';
      }
    },
    submitSignUp() {
      let loc_name = 'KOKAS';
      if (Cookies.get('store_detail') && Cookies.get('store_detail') != undefined) {
        try {
          const json_data = JSON.parse(Cookies.get('store_detail'));
          loc_name = json_data.name;
        } catch (err) {}
      }
      this.registerData.acquisition_source = `store-${loc_name.toLowerCase()}`;

      this.$MS_SOCO_PUBLIC_API_URL
        .post(`/auth/register`, this.registerData)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data.user;
            this.$store.dispatch('setProfile', data, { module: 'auth' });
            this.$router.push('/register/profile');
            this.$toasted.global.show('Pendaftaran account berhasil');
          } else if (!res.data.success && res.data && res.data.statusCode == 401) {
            this.$toasted.global.error('Session user sebelumnya masih terhubung');
            this.$store.dispatch('logout');
            this.$router.push('/register');
          } else if (!res.data.success && res.data.message) {
            this.$toasted.global.error(this.hummanizeMessage(res.data.message));
          }
        })
        .catch((err) => {
          console.error(err);
          if (err && err.response && err.response.status == 401) {
            this.$store.dispatch('logout');
            this.$router.push('/register');
            this.$toasted.global.error('Session user sebelumnya masih terhubung');
          } else {
            this.$toasted.global.error('Failed to call API. Silahkan Coba beberapa saat lagi');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
