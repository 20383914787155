<template>
  <div class="breadcrumps">
    <div class="breadcrumbs-steps">
      <div v-for="steps in total" :key="steps" class="steps" :class="{ active: steps <= current }"></div>
    </div>
    <span class="stucker"></span>
  </div>
</template>

<script>
export default {
  name: 'breadCrumbs',
  props: ['total', 'current'],
};
</script>

<style lang="scss" scoped>
.stucker {
  height: 20px;
  display: block;
  width: 100%;
  background-color: white;
}
.breadcrumbs-steps {
  position: fixed;
  top: 0;
  width: calc(101%);
  height: 20px;
  display: flex;
  right: -1%;
  z-index: 3;
  .steps {
    height: 20px;
    background-color: #e5e5e5;
    width: 16.6%;
    display: block;
    position: relative;
    border-right: 1px solid white;
    &.active {
      background-color: #f5b0b3;
    }
  }
}
</style>
